// TODO: isolate program > composables/smaller files before moving to composition API

<template>
  <h3>VIZBI <span>Live</span></h3>
  <div v-if="timeZoneDiffers" id="timezone">
    <!-- only show this div if the local and conference time zones differ -->
    Time zone:
    <Dropdown
      :placeholder="timeZoneSelected"
      :options="[timeZoneConference, timeZoneLocal].sort()"
      :selected="timeZoneSelected"
      :tag="false"
      @update="switchTimeZone" />
  </div>
  <div id="signin">
    <SignIn to="VIZBI 2024" @signedIn="signedIn = true" />
    <!-- Masterclass -->
  </div>
  <div v-if="signedIn" id="events">
    <p class="intro">
      This page gives access to the live video streams for the VIZBI Masterclass.
      <span v-if="showLinkAt < 1000">
        Zoom links for each event will appear below {{ showLinkAt }} minutes before the start time.
      </span>
      We recommend you install and use the
      <a href="https://support.zoom.us/hc/en-us/articles/201362233">latest Zoom desktop client</a>; however you can also
      use Zoom embedded within a browser.
    </p>
    <div v-if="host.match(/^local|^dev|^192/)">
      <!-- each click jumps to just before next event -->
      <button v-if="test_program.length" @click="reset">Reset</button>
      <button v-else @click="run_test">Run test</button>
    </div>
    <p class="intro">
      If you need help, you can contact the Masterclass support team via
      <a href="https://app.purechat.com/w/byqeil">this chat link</a>
    </p>
    <!-- Need key to change when items change. Was: `${i}.${reSorted}` -->
    <!-- was: (item, i) -->
    <EventItem
      v-for="item in items"
      :key="item.startTimeStamp"
      :item="item"
      :show-link-at="showLinkAt"
      @ended="eventHasEnded" />
  </div>
</template>

<script>
const {DateTime} = require("luxon");
import Dropdown from "@/components/Dropdown";
import EventItem from "@/components/EventItem";
import SignIn from "@/components/SignIn";
import stringify from "json-stringify-safe";
import timezoneHistory from "@/assets/history.json";
export default {
  name: "MasterclassLinks",
  components: {Dropdown, EventItem, SignIn},
  data() {
    return {
      event_to_test: 1,
      highlightedPerson: "",
      host: window.location.host,
      loginAction: "",
      signedIn: false,
      offset: 0, // for testing
      programOriginal: [],
      reSorted: 0, // counts re-sorting events
      session: "Masterclass",
      test_program: [], // used to test counters
      showLinkAt: 15, // by default, show link 15 mins prior
      timeZoneLocal: Intl.DateTimeFormat().resolvedOptions().timeZone,
      // timeZoneLocal = time zone of the user's computer
      timeZoneSelected: "",
      // timeZoneSelected = time zone currently selected by user
      year: 2024
    };
  },
  computed: {
    program() {
      this.log(`program()`);
      let output = [];
      let previousEndTime = "";
      let program = this.programOriginal; // local copy of original program
      function col(heading) {
        // return the index of the column with this heading
        return program[0].indexOf(heading);
      }
      // early return if the original program hasn't been mounted yet
      if (!Object.keys(this.programOriginal)) {
        return;
      }
      if (Object.keys(this.programOriginal).length === 0) {
        return;
      }
      //this.log(`Found events: ${Object.keys(this.programOriginal).length}`);

      for (let i = 1; i < program.length; i++) {
        let item = {}; // One event
        if (this.session) {
          // when this.session is set, check the current program item
          if (!program[i][col("Category")].includes(this.session)) {
            continue; // skip if not matches this session
          }
        }
        item.name = program[i][col("Name")];
        item.title = program[i][col("Talk/Role/Event")];
        if (!item.name && !item.title.match(/^meet|Q&A/i)) {
          // skip event items with no speaker or not beginning with 'meet'
          continue;
        }
        if (!program[i][col("Date")]) {
          continue; // skip items with no date
        }
        item.date = program[i][col("Date")];
        item.description = program[i][col("Description")];
        if (program[i][col("Duration/Time")]) {
          let eventTime = program[i][col("Duration/Time")];
          if (eventTime.match("-")) {
            let [start, end] = eventTime.split(/\s*-\s*/);
            //this.log(`Event start: ${start}, end: ${end}`);
            start = start.split(":");
            end = end.split(":");
            let date = item.date.split("/");
            item.start = DateTime.fromObject(
              {
                year: date[2],
                month: date[1],
                day: date[0],
                hour: start[0],
                minute: start[1],
                second: 0
              },
              {zone: this.timeZoneConference}
            );
            item.end = DateTime.fromObject(
              {
                year: date[2],
                month: date[1],
                day: date[0],
                hour: end[0],
                minute: end[1],
                second: 0
              },
              {zone: this.timeZoneConference}
            );
            if (this.timeZoneSelected === this.timeZoneLocal) {
              //this.log(`Change to local times: ${this.timeZoneLocal}`);
              item.start = item.start.toLocal();
              item.end = item.end.toLocal();
            }
          } else {
            let duration = parseInt(program[i][col("Duration/Time")]);
            //this.log(`Event duration: ${stringify(duration)}`);
            if (!previousEndTime) {
              console.error(`Need a previous event time`);
              continue; // "jumps over" one iteration in the for loop
            }
            item.start = previousEndTime;
            item.end = previousEndTime.plus({minute: duration});
          }
          previousEndTime = item.end; // remember for next event
        }
        item.startTimeStamp = item.start; // remember time stamp for countdown
        item.endTimeStamp = item.end; // remember time stamp for countdown
        this.log(`startTimeStamp = ${item.startTimeStamp}`);
        // now that times and dates are converted, format them
        item.date = item.start.weekdayLong + ", ";
        item.date += item.start.toFormat("LLL dd");
        item.start = this.formatTime(item.start);
        item.end = this.formatTime(item.end);
        item.ended = false; // true => event is over
        //this.log(`item.end: ${item.end}`);
        output.push(item);
      }
      return output;
    },
    timeZoneConference() {
      // time zone the conference is set in; read from VIZBI history file
      return timezoneHistory.years[this.year];
    },
    timeZoneDiffers() {
      // tests if the local time zone is the same as the conference time zone
      if (!this.timeZoneConference) {
        // early return if the original program hasn't been mounted yet
        return false;
      }
      let localTime = DateTime.local(); // get current time in local timeZone
      this.log(`timeZoneDiffers: localTime (${localTime})`);
      let timeInConferenceZone = localTime.setZone(this.timeZoneConference);
      // rezone to conference without changing timestamp;
      this.log(`timeZoneDiffers: timeInConferenceZone (${timeInConferenceZone})`);
      return timeInConferenceZone.toString() !== localTime.toString();
    },
    items() {
      this.log(`items(): reSorted = ${this.reSorted}`);
      let output = [];
      if (this.test_program.length) {
        this.log(`Running in test mode: using test program`);
        output = this.test_program;
      } else {
        this.log(`Running in normal mode: using real program`);
        output = this.program;
      }
      if (!this.reSorted) {
        return output;
      }
      this.log(`check if items need to be (re-)sorted`);
      let [first, second, ...rest] = output;
      this.log(`live/first = ${stringify(first)}`);
      let i = 1; // prevent infinite loop
      while (first.ended && i <= output.length) {
        this.log(`first = ${stringify(first)}`);
        this.log(`second = ${stringify(second)}`);
        this.log(`first item has ended; move to end`);
        output = [second, ...rest, first];
        [first, second, ...rest] = output;
        i++;
        // this.log(`item.end: ${item.end}`);
      }
      return output;
    }
  },
  created() {
    this.log(`created()`);
    this.setDayAndTimeZoneFromURL();
    try {
      this.programOriginal = require(`@/assets/years/${this.year}/Program/program.json`);
    } catch (error) {
      console.error(`Could not load program file: ${error}`);
      //alert(`Could not load program file: ${error}`);
    }
  },
  methods: {
    // eslint-disable-next-line
    log(message) {
      if (process.env.NODE_ENV === "development") {
        console.log(message); // uncomment to show logs from this component
      }
    },
    switchTimeZone(newTimeZone) {
      localStorage.setItem("timeZoneSelected", newTimeZone);
      this.timeZoneSelected = newTimeZone;
      this.setDayAndTimeZoneInUrl(this.daySelected, newTimeZone);
    },
    setDayAndTimeZoneFromURL() {
      let parameters = this.$route.hash.replace(/^#/, "").split("&");
      if (this.$route.name === "Program") {
        // only the Program page can have a day in the URL
        if (this.days.includes(parameters[0])) {
          // if the first paramter is a day, set the daySelected variable
          this.log(`URL includes day '${parameters[0]}'`);
          this.daySelected = parameters[0];
          // them remove the day from the parameter array
          parameters.shift();
        }
      }
      if (parameters[0] && parameters[0].match(/-/)) {
        // if the next remaining parameter is a time zone, ...
        this.log(`URL includes time zone '${parameters[0]}'`);
        /// overwrite this with the local time zone
        this.timeZoneSelected = this.timeZoneLocal;
        localStorage.setItem("timeZoneSelected", this.timeZoneLocal);
        return;
      } else if (localStorage.getItem("timeZoneSelected")) {
        // if no time zone is set in the URL, ...
        this.timeZoneSelected = localStorage.getItem("timeZoneSelected");
        this.log(`Using time zone in localStorage: ${this.timeZoneSelected}`);
      } else {
        // initially, defaults to conference time zone (not local time zone).
        // user can then change it
        this.timeZoneSelected = this.timeZoneConference;
      }
    },
    formatTime(date) {
      if (!date) {
        return;
      } else if (this.timeZoneSelected.match(/London|Australia|America/)) {
        // use 12 hour clock + AM/PM for these countries
        return date.toFormat("h:mma").toLowerCase();
      } else {
        // use 24 hour clock for all other countries
        return date.toFormat("T").toLowerCase();
      }
    },
    run_test() {
      this.log(`Test button pressed: calculating test program`);
      let i = 1;
      let output = [];
      for (const original_item of this.program) {
        const item = Object.assign({}, original_item);
        item.startTimeStamp = DateTime.now().plus({minutes: i});
        item.end = DateTime.now().plus({minutes: i + 1});
        item.start = this.formatTime(item.startTimeStamp);
        item.end = this.formatTime(item.end);
        item.date = item.startTimeStamp.weekdayLong + ", ";
        item.date += item.startTimeStamp.toFormat("LLL dd");
        output.push(item);
        i++;
      }
      // reset sort counter and test_program
      [this.reSorted, this.test_program] = [0, output];
      this.showLinkAt = 0.5; // switch to showLinkAt 1 min prior
    },
    reset() {
      this.showLinkAt = 30; // return to showing 15min prior
      // reset sort counter and turn off test
      [this.reSorted, this.test_program] = [0, []];
    },
    eventHasEnded(timeStamp) {
      //this.log(`Live/eventHasEnded(${stringify(timeStamp)})`);
      for (let item of this.program) {
        if (item.startTimeStamp === timeStamp) {
          this.log(`Live/eventHasEnded: title = ${item.title}`);
          item.ended = true;
          this.log(`live/item = ${stringify(item)}`);
          this.reSorted++; // triggers items to be re-sorted
          return; // break loop & exit
        }
      }
      console.error(`Live/eventHasEnded: couldn't find matching item`);
    }
  }
};
</script>

<style scoped>
h3 {
  display: inline;
  float: left;
}
#timezone {
  float: right;
  margin-top: 12px; /* same as App.vue/h3 */
  margin-bottom: 8px; /* same as App.vue/h3 */
  font-size: 14px;
  line-height: 33px;
  clear: right; /* https://stackoverflow.com/q/3415635 */
}
#signin {
  clear: both; /* needed to clear two floats above */
  margin-top: 5px;
}
#events {
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
p.intro {
  font-weight: 300;
  font-style: italic;
}
div.description {
  text-align: left;
  text-decoration: none;
  font-size: 1.1rem;
  color: black;
}
h3 span {
  font-weight: 300;
}
</style>
