// TODO: isolate program > composables/smaller files before moving to composition API

<template>
  <h3>VIZBI <span>Live</span></h3>
  <div v-if="timeZoneDiffers" id="timezone">
    <!-- only show this div if the local and conference time zones differ -->
    Time zone:
    <Dropdown
      :placeholder="timeZoneSelected"
      :options="[timeZoneConference, timeZoneLocal].sort()"
      :selected="timeZoneSelected"
      :tag="false"
      @update="switchTimeZone" />
  </div>
  <br />
  <div v-if="!signedIn" id="signin">
    <SignIn to="VIZBI 2024" @signedIn="signedIn = true" />
    <!-- Masterclass -->
  </div>
  <div v-else id="events">
    <p class="intro">
      This page gives access to the live video streams for the VIZBI Masterclass.
      <span v-if="showLinkAt < 1000">
        Zoom links for each event will appear below {{ showLinkAt }} minutes before the start time.
      </span>
      We recommend you install and use the
      <a href="https://support.zoom.us/hc/en-us/articles/201362233">latest Zoom desktop client</a>; however you can also
      use Zoom embedded within a browser.
    </p>
    <div v-if="host.match(/^local|^dev|^192/)">
      <!-- each click jumps to just before next event -->
      <button v-if="test_program.length" @click="reset">Reset</button>
      <button v-else @click="run_test">Run test</button>
    </div>
    <p class="intro">
      If you need help, you can contact the Masterclass support team via
      <a href="https://app.purechat.com/w/byqeil">this chat link</a>
    </p>
    <!-- Need key to change when items change. Was: `${i}.${reSorted}` -->
    <!-- was: (item, i) -->
    <EventItem
      v-for="item in items"
      :key="item.startTimeStamp"
      :item="item"
      :show-link-at="showLinkAt"
      @ended="eventHasEnded" />
  </div>
</template>

<script setup>
const log = (message) => console.log(message); // eslint-disable-line

const {DateTime} = require("luxon");
import Dropdown from "@/components/Dropdown";
import EventItem from "@/components/EventItem";
import SignIn from "@/components/SignIn";
import stringify from "json-stringify-safe";
import history from "@/assets/history.json";

const year = history.latestYear;
const timeZoneConference = history.years[year];
const host = window.location.host;
const timeZoneLocal = Intl.DateTimeFormat().resolvedOptions().timeZone;
let showLinkAt = 15; // by default, show link 15 mins prior

import {ref} from "vue";
const session = ref("Masterclass");
// const event_to_test = ref(1);
// const highlightedPerson = ref("");
// const loginAction = ref("");
const timeZoneSelected = ref(""); // time zone currently selected by user
const signedIn = ref(false);
// const offset = ref(0); // for testing
const reSorted = ref(0); // counts re-sorting events
const programOriginal = ref([]); // for testing
const test_program = ref([]); // for testing
// const daySelected = ref(""); // UNDEFINED IN Live-old.vue

import {computed} from "vue";

const program = computed(() => {
  log(`program()`);
  let output = [];
  let previousEndTime = "";
  let program = programOriginal.value; // local copy of original program
  function col(heading) {
    // return the index of the column with this heading
    return program[0].indexOf(heading);
  }
  // early return if the original program hasn't been mounted yet
  if (!Object.keys(programOriginal.value)) {
    return;
  }
  if (Object.keys(programOriginal.value).length === 0) {
    return;
  }
  //log(`Found events: ${Object.keys(programOriginal.value).length}`);

  for (let i = 1; i < program.length; i++) {
    let item = {}; // One event
    if (session.value) {
      // when session.value is set, check the current program item
      if (!program[i][col("Category")].includes(session.value)) {
        continue; // skip if not matches this session
      }
    }
    item.name = program[i][col("Name")];
    item.title = program[i][col("Talk/Role/Event")];
    if (!item.name && !item.title.match(/^meet|Q&A/i)) {
      // skip event items with no speaker or not beginning with 'meet'
      continue;
    }
    if (!program[i][col("Date")]) {
      continue; // skip items with no date
    }
    item.date = program[i][col("Date")];
    item.description = program[i][col("Description")];
    if (program[i][col("Duration/Time")]) {
      let eventTime = program[i][col("Duration/Time")];
      if (eventTime.match("-")) {
        let [start, end] = eventTime.split(/\s*-\s*/);
        //log(`Event start: ${start}, end: ${end}`);
        start = start.split(":");
        end = end.split(":");
        let date = item.date.split("/");
        item.start = DateTime.fromObject(
          {
            year: date[2],
            month: date[1],
            day: date[0],
            hour: start[0],
            minute: start[1],
            second: 0
          },
          {zone: timeZoneConference}
        );
        item.end = DateTime.fromObject(
          {
            year: date[2],
            month: date[1],
            day: date[0],
            hour: end[0],
            minute: end[1],
            second: 0
          },
          {zone: timeZoneConference}
        );
        if (timeZoneSelected.value === timeZoneLocal) {
          //log(`Change to local times: ${timeZoneLocal}`);
          item.start = item.start.toLocal();
          item.end = item.end.toLocal();
        }
      } else {
        let duration = parseInt(program[i][col("Duration/Time")]);
        //log(`Event duration: ${stringify(duration)}`);
        if (!previousEndTime) {
          console.error(`Need a previous event time`);
          continue; // "jumps over" one iteration in the for loop
        }
        item.start = previousEndTime;
        item.end = previousEndTime.plus({minute: duration});
      }
      previousEndTime = item.end; // remember for next event
    }
    item.startTimeStamp = item.start; // remember time stamp for countdown
    item.endTimeStamp = item.end; // remember time stamp for countdown
    log(`startTimeStamp = ${item.startTimeStamp}`);
    // now that times and dates are converted, format them
    item.date = item.start.weekdayLong + ", ";
    item.date += item.start.toFormat("LLL dd");
    item.start = formatTime(item.start);
    item.end = formatTime(item.end);
    item.ended = false; // true => event is over
    //log(`item.end: ${item.end}`);
    output.push(item);
  }
  return output;
});

const timeZoneDiffers = computed(() => {
  // tests if the local time zone is the same as the conference time zone
  if (!timeZoneConference) {
    // early return if the original program hasn't been mounted yet
    return false;
  }
  let localTime = DateTime.local(); // get current time in local timeZone
  log(`timeZoneDiffers: localTime (${localTime})`);
  let timeInConferenceZone = localTime.setZone(timeZoneConference);
  // rezone to conference without changing timestamp;
  log(`timeZoneDiffers: timeInConferenceZone (${timeInConferenceZone})`);
  return timeInConferenceZone.toString() !== localTime.toString();
});

const items = computed(() => {
  log(`items: reSorted = ${reSorted.value}`);
  let output = [];
  if (test_program.value.length) {
    log(`Running in test mode: using test program`);
    output = test_program.value;
  } else {
    log(`Running in normal mode: using real program`);
    output = program.value;
  }
  if (!reSorted.value) {
    return output;
  }
  log(`check if items need to be (re-)sorted`);
  let [first, second, ...rest] = output;
  log(`live/first = ${stringify(first)}`);
  let i = 1; // prevent infinite loop
  while (first.ended && i <= output.length) {
    log(`first = ${stringify(first)}`);
    log(`second = ${stringify(second)}`);
    log(`first item has ended; move to end`);
    output = [second, ...rest, first];
    [first, second, ...rest] = output;
    i++;
    // log(`item.end: ${item.end}`);
  }
  return output;
});

import {onMounted} from "vue";
onMounted(() => {
  log(`onMounted()`);
  setDayAndTimeZoneFromURL();
  try {
    programOriginal.value = require(`@/assets/years/${year}/Program/program.json`);
  } catch (error) {
    console.error(`Could not load program file: ${error}`);
    // alert(`Could not load program file: ${error}`); // Uncomment if needed
  }
});

function switchTimeZone(newTimeZone) {
  localStorage.setItem("timeZoneSelected", newTimeZone);
  timeZoneSelected.value = newTimeZone;
  setTimeZoneInUrl(newTimeZone);
}

import {useRoute} from "vue-router";
const route = useRoute();
function setDayAndTimeZoneFromURL() {
  let parameters = route.hash.replace(/^#/, "").split("&");
  if (parameters[0] && parameters[0].match(/-/)) {
    // if the next remaining parameter is a time zone, ...
    log(`URL includes time zone '${parameters[0]}'`);
    /// overwrite this with the local time zone
    timeZoneSelected.value = timeZoneLocal;
    localStorage.setItem("timeZoneSelected", timeZoneLocal);
    return;
  } else if (localStorage.getItem("timeZoneSelected")) {
    // if no time zone is set in the URL, ...
    timeZoneSelected.value = localStorage.getItem("timeZoneSelected");
    log(`Using time zone in localStorage: ${timeZoneSelected.value}`);
  } else {
    // initially, defaults to conference time zone (not local time zone).
    // user can then change it
    timeZoneSelected.value = timeZoneConference;
  }
}
function formatTime(date) {
  if (!date) {
    return;
  } else if (timeZoneSelected.value.match(/London|Australia|America/)) {
    // use 12 hour clock + AM/PM for these countries
    return date.toFormat("h:mma").toLowerCase();
  } else {
    // use 24 hour clock for all other countries
    return date.toFormat("T").toLowerCase();
  }
}
function run_test() {
  log(`Test button pressed: calculating test program`);
  let i = 1;
  let output = [];
  for (const original_item of program.value) {
    const item = Object.assign({}, original_item);
    item.startTimeStamp = DateTime.now().plus({minutes: i});
    item.end = DateTime.now().plus({minutes: i + 1});
    item.start = formatTime(item.startTimeStamp);
    item.end = formatTime(item.end);
    item.date = item.startTimeStamp.weekdayLong + ", ";
    item.date += item.startTimeStamp.toFormat("LLL dd");
    output.push(item);
    i++;
  }
  // reset sort counter and test_program
  [reSorted.value, test_program.value] = [0, output];
  showLinkAt.value = 0.5; // switch to showLinkAt 1 min prior
}
function reset() {
  showLinkAt.value = 30; // return to showing 15min prior
  // reset sort counter and turn off test
  [reSorted.value, test_program.value] = [0, []];
}
function eventHasEnded(timeStamp) {
  //log(`Live/eventHasEnded(${stringify(timeStamp)})`);
  for (let item of program.value) {
    if (item.startTimeStamp === timeStamp) {
      log(`Live/eventHasEnded: title = ${item.title}`);
      item.ended = true;
      log(`live/item = ${stringify(item)}`);
      reSorted.value++; // triggers items to be re-sorted
      return; // break loop & exit
    }
  }
  console.error(`Live/eventHasEnded: couldn't find matching item`);
}

function setTimeZoneInUrl(timeZone) {
  this.log(`setTimeZoneInUrl(${timeZone})`);
  if (!timeZone) return;
  if (timeZone === timeZoneConference) {
    history.pushState({}, null, route.path);
  } else {
    const hash = timeZone.replace("/", "-");
    history.pushState({}, null, `${route.path}#${hash}`);
  }
}
</script>

<style scoped>
h3 {
  display: inline;
  float: left;
}
#timezone {
  float: right;
  margin-top: 12px; /* same as App.vue/h3 */
  margin-bottom: 8px; /* same as App.vue/h3 */
  font-size: 14px;
  line-height: 33px;
  clear: right; /* https://stackoverflow.com/q/3415635 */
}
#signin {
  clear: both; /* needed to clear two floats above */
  margin-top: 5px;
}
#events {
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
p.intro {
  font-weight: 300;
  font-style: italic;
}
div.description {
  text-align: left;
  text-decoration: none;
  font-size: 1.1rem;
  color: black;
}
h3 span {
  font-weight: 300;
}
</style>
