<template>
  <div v-if="registrationOpen" ref="banner" :class="{hideForm: route.name === 'Form'}">
    <span class="left-text">
      <span id="lineBreak">
        USC,
        <span v-if="displayWidth === 'unlimited' && route.name !== 'Registration'">
          Los Angeles</span
        >
        <span v-else>L.A.</span>
      </span>
      and online
    </span>
    <RegisterNow />
    <span class="right-text">March<br />12–15</span>
  </div>
</template>

<script setup>
const log = (message) => console.log(message); // eslint-disable-line
import {inject, nextTick, onMounted, ref, watch} from "vue";
import {useRoute} from "vue-router";
import RegisterNow from "@/components/CallToAction/RegisterNow.vue";
import {setCallToActionStyle} from "@/composables/useBackground"; // fit to call-to-action
const displayWidth = inject("displayWidth"); // from App.vue > composable/
const [banner, registrationOpen] = [ref(null), false];
const route = useRoute(); // access the current route object
let height = "80px"; // initially, set to default for narrow displays
function setBannerHeight() {
  if (displayWidth.value === "medium") {
    height = "90px";
  } else if (displayWidth.value === "wide") {
    height = "100px";
  } else if (displayWidth.value === "unlimited") {
    height = "105px";
  }
  log(`CTA displayWidth = ${displayWidth.value}`);
  log(`CTA height = ${height}`);
  banner.value.style.height = height; // Set desired height for <div></div>
  // Use nextTick to apply styles after Vue has completed the DOM update
  nextTick(() => {
    if (banner.value) {
      banner.value.style.height = height;
      setCallToActionStyle(height, "#000"); // Adjust <body>
    }
  });
}
// Set div's height once component is mounted
onMounted(() => {
  if (banner.value) setBannerHeight(height);
});
// hide CTA on the Registration/Form page:
watch(
  () => route.name,
  (newRouteName, oldRouteName) => {
    log(`CTA [newRouteName, oldRouteName] = ${newRouteName}, ${oldRouteName}]`);
    if (newRouteName === "Form" || !registrationOpen) {
      log(`Hide CTA `);
      setCallToActionStyle("0px", "#000");
    } else if (oldRouteName === "Form") {
      log(`Reveal CTA`);
      setBannerHeight();
    }
  }
);
// resize CTA with displayWidth changes
watch(displayWidth, (newDisplayWidth, oldisplayWidth) => {
  log(`CTA [newDisplayWidth, oldisplayWidth] = ${newDisplayWidth}, ${oldisplayWidth}]`);
  if (route.name === "Form") return; // no change
  if (newDisplayWidth === oldisplayWidth) return; // no change
  setBannerHeight();
});
</script>

<style scoped>
div {
  display: flex;
  justify-content: space-between; /* Keeps left and right text at the sides */
  align-items: center;
  position: relative; /* New */
  color: white;
  font-size: 1rem;
  padding: 0 1.05rem 0 0.75rem;
  line-height: 1.05;
}
:is(.wide div, .medium div) {
  font-size: clamp(1.2rem, 4vw, 1.73rem); /* min, preferred, max was: 3.12em; */
}
.unlimited div {
  font-size: 1.73rem;
  height: 100px;
}
.left-text,
.right-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.left-text {
  align-items: flex-start;
}
.right-text {
  align-items: flex-end;
}
.left-text span {
  display: inline;
}
#lineBreak {
  display: block; /* Forces a new line */
}
#lineBreak span {
  display: inline; /* prevent above new line */
}
.hideForm {
  display: none; /* Or 'visibility: hidden;' if you want to keep the space */
}
</style>
