<template>
  <p>
    <em><strong>PLEASE NOTE: CHANGE OF VENUE !</strong></em
    ><br />VIZBI 2024 will take place in the
    <a
      href="https://hospitality.usc.edu/dining_locations/usc-hotel-grand-ballroom-and-conference-rooms/">
      USC Hotel's Grand Ballroom </a
    >, located at the University of Southern California's Park campus in Los Angeles, USA.
  </p>
  <!-- <a href="https://maps.app.goo.gl/23rUjjQpDPqAZjqU8">
    <HeroImage file="2024/Venue/img/img.001.jpeg" />
  </a> -->

  <h4>Getting around</h4>
  <p>
    USC provides comprehensive information on
    <a href="https://transnet.usc.edu/">transportation options</a>, including details on
    <a href="https://transnet.usc.edu/index.php/daily-and-hourly-parking/">parking</a>. To navigate
    to the USC Hotel, go to:
    <a href="https://maps.app.goo.gl/23rUjjQpDPqAZjqU8">
      USC Hotel, 3540 S Figueroa St, Los Angeles, CA 90007 </a
    >.
  </p>
  <div class="fl-map">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3306.9589817394617!2d-118.2838718245706!3d34.01926381947148!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2c7d67ce0e44b%3A0x7dc42ec10e7a5d32!2sUSC%20Hotel!5e0!3m2!1sen!2suk!4v1709151261946!5m2!1sen!2suk"
      aria-hidden="true"
      title="USC Hotel Ballroom" />
  </div>
  <h4 id="Accommodation">Accommodation</h4>
  <p>
    Accommodation is not included in the registration fee of the VIZBI conference or Masterclass. We
    strongly recommend staying at the conference's venue,
    <a href="https://uschotel.usc.edu">The USC Hotel</a>.
    <!-- For
    VIZBI delegates, we have arranged a discounted rate of $189 per night. Note that this does not include charges for
    extra services, such as parking (available for $40/day). To take advantage of this special rate, please register
    using
    <a
      href="https://urldefense.com/v3/__https:/be.synxis.com/?Hotel=5374&Chain=24206&arrive=3*12*2024&depart=3*15*2024&group=24032024VI__;Ly8vLw!!LIr3w8kk_Xxm!oIaohIBYAIMyefh_vwF5SWHd6kB76Ep0wpiz39Ox-wR2lSFkSji4Jmx989QfzriehERDD9-tfWboZf29a_4$">this
      USC Hotel link</a>. Alternatively, you can call the USC Hotel on 213.748.4141 and mention the group name "VIZBI
    Conference". To
    receive this discounted rate, you must make your reservation before <b
      style="text-decoration-line: line-through;">Sunday, February 11, 2024</b>.-->
  </p>
  <h4 id="restaurants">Restaurants at USC Village and Downtown</h4>
  <p>
    Our hosts and colleagues at USC have provided the recommendations below for dinner on Tuesday
    and Wednesday evening during the VIZBI program. Please note, the only dinner provided for
    workshop participants is on Thursday, during the Art and Biology evening event.
  </p>
  <p>
    <strong>Tuesday Evening</strong> there will be an informal outing to the USC Village (see
    <a href="/2024/USC-UPC-Map-2022.pdf">map</a>) for all VIZBI Masterclass and Workshop
    participants interested in meeting up.
  </p>
  <p>
    <strong>Wednesday Evening</strong> VIZBI workshop particpants are encouraged to join others at a
    nearby USC Village venue, or venture further afield.
  </p>
  <p>On "Fig" (Figueroa St.):</p>
  <ul>
    <li>
      <strong>Jacks N Joes</strong> – coffee and breakfast spot on Fig
      <br />
      <a href="https://maps.app.goo.gl/U28gT4C6t7Yc7qFUA">
        2498 S Figueroa St, Los Angeles, CA 90007
      </a>
    </li>
    <li>
      <strong>Thai by Trio</strong> –good Thai place
      <br />

      <a href="https://maps.app.goo.gl/kmuaUwVT9ApAzFCU9">
        2700 S Figueroa St #101, Los Angeles, CA 90007
      </a>
    </li>
  </ul>
  <p>At LA Live:</p>
  <ul>
    <li>
      <strong>Yard House</strong> – upscale bar and restaurant
      <br />
      <a href="https://maps.app.goo.gl/KvYfNKE3rNtDoL3p6">
        800 W Olympic Blvd A-115, Los Angeles, CA 90015
      </a>
    </li>
    <li>
      <strong>Lazy Dog</strong> – casual bar and burgers
      <br />
      <a href="https://maps.app.goo.gl/TzFFpux2VdLfPBLn8">
        800 W Olympic Blvd A-180, Los Angeles, CA 90015
      </a>
    </li>
    <li>
      <strong>Fixin Soul Kitchen</strong> – roomy comfort food
      <br />
      <a href="https://maps.app.goo.gl/u8RNBe3dXVyhH4TQ8">
        800 W Olympic Blvd A150, Los Angeles, CA 90015
      </a>
    </li>
  </ul>
  <p>Closer to downtown:</p>
  <ul>
    <li>
      <strong>Pantry Cafe</strong> – breakfast / brunch spot
      <br />
      <a href="https://maps.app.goo.gl/c2VNFEgVQF6rBMAh7">
        877 S Figueroa St, Los Angeles, CA 90017
      </a>
    </li>
    <li>
      <strong>Rooftop at the Wayfarer</strong> – rooftop restaurant
      <br />
      <a href="https://maps.app.goo.gl/koxc85Pv2ysXcvM28">813 Flower St, Los Angeles, CA 90017</a>
    </li>
  </ul>
  <p>At USC Village:</p>
  <ul>
    <li>
      <strong>Cava</strong> - Mediterranean Restaurant
      <br />
      <a href="https://maps.app.goo.gl/2haydEJRHjVuWWa8A">
        3201 S Hoover St Suite 1840, Los Angeles, CA 90089
      </a>
    </li>
    <li>
      <strong>Chinese Street Food</strong> - Chinese restaurant
      <br />
      <a href="https://maps.app.goo.gl/ZPdYwNcatvowUR7p7">
        3201 S Hoover St #1870, Los Angeles, CA 90007
      </a>
    </li>
    <li>
      <strong>City tacos</strong> - Mexican Restaurant
      <br />
      <a href="https://maps.app.goo.gl/1gjEkBPK4w4kBMhaA">
        835 W Jefferson Blvd Ste 1735, Los Angeles, CA 90089
      </a>
    </li>
    <li>
      <strong>Green Leaf Kitchen and Cocktails</strong> - New American restaurant
      <br />
      <a href="https://maps.app.goo.gl/xhvWHTJ4ifUfbcWC6">
        929 W Jefferson Blvd #1650, Los Angeles, CA 90089
      </a>
    </li>
    <li>
      <strong>Kobunga</strong> - Korean BBQ Restaurant
      <br />
      <a href="https://maps.app.goo.gl/uZYKYZqX2TjvZ3M98">
        929 W Jefferson Blvd Suite 1610, Los Angeles, CA 90007
      </a>
    </li>
    <li>
      <strong>Rock and Reillys</strong> - American Restaurant
      <br />
      <a href="https://maps.app.goo.gl/YMstt2fjrPUHPPCz6">
        3201 S Hoover St, Los Angeles, CA 90089
      </a>
    </li>
    <li>
      <strong>Pizza Please</strong>
      <br />
      <a href="https://maps.app.goo.gl/VWYozNjQx6LuEDR69">
        835 W Jefferson Blvd #1740, Los Angeles, CA 90089
      </a>
    </li>
    <li>
      <strong>Ramen Kenjo</strong>- Ramen Restaurant
      <br />
      <a href="https://maps.app.goo.gl/q67zHCgMxxSU2P2SA">
        929 W Jefferson Blvd, Los Angeles, CA 9000
      </a>
    </li>
    <li>
      <strong>Stout Burgers & Beers</strong> - American Restaurant - Burgers<br />
      <a href="https://maps.app.goo.gl/wdhApLiUJN3qweKW8">
        835 W Jefferson Blvd Unit 1710, Los Angeles, CA 90089
      </a>
    </li>
  </ul>
</template>

<!-- <script setup>
import HeroImage from "@/components/HeroImage.vue";
</script> -->

<style scoped>
iframe {
  width: 100%;
  height: 400px;
}
div#hotels {
  columns: 3;
}
div#hotels a {
  display: block;
  margin-bottom: 8px;
}
img {
  width: 100%;
}
p.centered {
  text-align: center;
}
</style>
