<!-- Copyright: Seán I. O'Donoghue -->

<!-- eslint-disable vue/no-v-html -->
<template>
  <DialogModal v-if="currentPerson" :fixed-width="false" @hide="hitItAndQuit">
    <figure v-if="people && people[currentPerson]" @click.stop>
      <a :href="people[currentPerson].URL" class="headshot">
        <img v-if="headshot(currentPerson)" :src="headshot(currentPerson)" />
        <img v-else-if="smallHeadshot(currentPerson)" class="small" :src="smallHeadshot(currentPerson)" />
      </a>
      <figcaption>
        <!-- person's name -->
        <div>
          <a :href="people[currentPerson].URL">
            <h4>
              <!-- https://uxwing.com/hyperlink-icon/ -->
              {{ currentPerson }}
              <img src="@/assets/img/hyperlink.svg" />
            </h4>
          </a>
          <!-- affiliation -->
          <p class="affiliation">{{ people[currentPerson].Affiliation }}</p>
        </div>
        <SwitchBespoke
          v-if="bio && description"
          :show-bio="showBio"
          style="float: right"
          @update="showBio = !showBio" />
        <br />
        <p v-if="bio && showBio" class="bio" v-html="bio" />
        <p v-else-if="description" class="bio">
          <Slides :name="currentPerson" align="left" />
          <span v-html="description" />
        </p>
        <p v-else-if="topic" class="topic" v-html="topic" />
      </figcaption>
    </figure>
  </DialogModal>
</template>

<script>
//import ButtonBespoke from '@/components/ButtonBespoke';
import DialogModal from "@/components/DialogModal";
import stringify from "json-stringify-safe";
import SwitchBespoke from "@/components/SwitchBespoke";
import Slides from "@/components/People/Person/Slides";
export default {
  name: "PersonHighlight",
  //emits: ['closeHighlight'], // https://stackoverflow.com/q/64220737
  components: {DialogModal, SwitchBespoke, Slides}, //Button
  props: {
    category: {type: String, default: ""},
    day: {type: String, default: ""},
    people: {type: Object, default: () => {}},
    person: {type: String, required: true},
    selectedPeople: {type: Array, default: () => []}
  },
  data() {
    return {
      currentPerson: "",
      hashBaseParams: "", // hash parameters aside from person's name
      personInInitialUrl: "",
      showBio: true
    };
  },
  computed: {
    description() {
      this.log(`category = ${this.category}`);
      if (this.people && this.people[this.currentPerson] && this.people[this.currentPerson]["Description"]) {
        this.log("if there is at least one talk description, ...");
        let description = this.people[this.currentPerson]["Description"];
        this.log(`description = ${stringify(description)}`);
        if (this.category && description[this.category]) {
          this.log("return description for currently selected session");
          return description[this.category];
        } else {
          this.log("no session is selected, so return first description");
          // https://stackoverflow.com/q/3298477
          return Object.values(description)[0];
        }
      } else {
        // if no description, return false
        return false;
      }
    },
    bio() {
      if (this.people && this.people[this.currentPerson] && this.people[this.currentPerson]["Bio"]) {
        return this.people[this.currentPerson]["Bio"];
      } else {
        return false;
      }
    },
    topic() {
      if (!this.people) return false;
      if (!this.people[this.currentPerson]) return false;
      console.log(JSON.stringify(this.people[this.currentPerson]));
      if (!this.people[this.currentPerson]["Topic"]) return false;
      return this.people[this.currentPerson]["Topic"];
    }
  },
  watch: {
    selectedPeople: function () {
      // newVal, oldVal
      this.log("selectedPeople changed");
      if (this.person) {
        this.currentPerson = this.person;
        return; // early exit
      }
      if (this.personInInitialUrl) {
        if (this.selectedPeople.includes(this.personInInitialUrl)) {
          this.currentPerson = this.personInInitialUrl;
          this.setPersonInUrl(this.currentPerson);
          this.log(`'${this.personInInitialUrl}' is in ${stringify(this.selectedPeople)}`);
        } else {
          this.log(`'${this.personInInitialUrl}' is not in ${stringify(this.selectedPeople)}`);
        }
        this.personInInitialUrl = ""; // forgot person in initial URL
      }
    }
  },
  mounted() {
    this.log(`personHightlight() mounted`);
    if (this.person) {
      this.log(`personHightly mounted for ${this.person}`);
      if (this.personInInitialUrl) {
        this.personInInitialUrl = ""; // forgot person in initial URL
      }
      this.currentPerson = this.person;
      this.setPersonInUrl(this.currentPerson);
      return; //early exit
    }
    if (window.location.hash) {
      // this.$route.hash doesn't work
      // decodeURIComponent
      let hash = decodeURIComponent(window.location.hash);
      this.log(`URL contains hash: '${hash}'`);
      let params = hash.replace(/#/, "").split(/&/);
      if (params && params.length > 0) {
        this.log(`URL contains parameters: '${stringify(params)}'`);
        // https://stackoverflow.com/q/60548305
        let lastParam = params.at(-1);
        if (lastParam.match(/_/)) {
          this.log(`URL contains a person's name: ${lastParam}`);
          this.personInInitialUrl = lastParam.replaceAll(/_/g, " ");
          params.shift(); // remove last parameter from list
          this.setPersonInUrl(""); // clear url to prevent progegation
        }
        if (params.length > 0) {
          this.log(`URL contains other parameters: ${stringify(params)}`);
          this.hashBaseParams = params.join("&");
          this.log(`this.hashBaseParams = ${this.hashBaseParams}`);
        }
      }
    }
  },
  methods: {
    // eslint-disable-next-line
    log(message) {
      if (process.env.NODE_ENV === "development") {
        //console.log(message); // uncomment to show logs from this component
      }
    },
    goTo(person) {
      this.currentPerson = person;
      this.setPersonInUrl(person);
    },
    underscore(person) {
      return person.replaceAll(" ", "_");
    },
    headshot(person) {
      try {
        return require(`@/assets/years/${this.$route.params.year}/People/img/large/${this.underscore(person)}.jpg`);
      } catch (error) {
        this.log(`No large headshot found for ${person}`);
        return false;
      }
    },
    smallHeadshot(person) {
      try {
        return require(`@/assets/years/${this.$route.params.year}/People/img/${person.replaceAll(" ", "_")}.png`);
      } catch (error) {
        this.log(`Failed to find image for ${person}`);
        return require(`@/assets/years/${this.$route.params.year}/People/img/large/Unknown.jpg`);
      }
    },
    setPersonInUrl(currentPerson) {
      this.log(`setPersonInUrl(${currentPerson})`);
      if (currentPerson) {
        currentPerson = this.underscore(currentPerson);
      }
      let newHash = "";
      if (this.hashBaseParams && currentPerson) {
        newHash = this.hashBaseParams + "&" + currentPerson;
      } else if (currentPerson) {
        // if no hashBaseParams
        newHash = currentPerson;
      }
      this.log(`about to set hash: ${newHash}`);
      this.addHashToLocation(newHash);
    },
    addHashToLocation(newHash) {
      // silently-update-url-without-triggering-route-in-vue-router
      // https://stackoverflow.com/q/51337255/
      this.log(`addHashToLocation(${newHash})`);
      if (newHash) {
        history.pushState({}, null, `${this.$route.path}#${newHash}`);
      } else {
        // empty hash
        history.pushState({}, null, this.$route.path);
      }
    },
    hitItAndQuit() {
      this.log(`personHighlight/Let's hitItAndQuit()`);
      this.log(`personHighlight/hitItAndQuit()`);
      this.currentPerson = ""; // disables highlight
      this.setPersonInUrl(); // clear URL
    }
  }
};
</script>

<style scoped>
figure {
  margin: auto;
  max-width: 95%;
  overflow: hidden; /* Add this line */
  /* clear: both; */
}
.headshot img {
  position: relative;
  width: 100%;
  max-width: 600px;
  display: block;
  margin: auto;
}
body:not(.narrow) .headshot img.small {
  width: calc(167px * 1.3); /* if only small img available, scale it up a bit */
  height: auto; /* This maintains the aspect ratio */
  object-fit: contain; /* Fit within the parent container */
}
figcaption {
  margin: 20px 0 10px 0;
}
figcaption div {
  float: left;
  text-align: left;
}
h4 {
  /*  offset the hyperlink SVG */
  color: black;
  font-weight: 600;
  font-size: clamp(16px, 2.5vw, 1.3em);
  line-height: 1em;
  margin: 0px;
  /* top, right, bottom, and left */
}
svg {
  margin-right: 8px;
}
p.affiliation {
  margin: 0px;
  font-size: clamp(0.8em, 2vw, 1.1em);
}
br {
  clear: both;
}
p.bio {
  max-width: 600px;
  font-size: 0.9em;
  line-height: 1.3em;
  margin: 8px 0 8px 0;
  /* top, right, bottom, and left */
  hyphens: auto;
  text-align: justify;
}
p.topic {
  max-width: 600px;
  font-size: 0.9em;
  line-height: 1.3em;
  margin: 8px 0 8px 0;
  /* top, right, bottom, and left */
  hyphens: auto;
  text-align: left;
}
</style>
