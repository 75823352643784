<template>
  <router-link v-if="soldOut || route.name === 'Showcase'" to="/2024/Registration" class="soldOut">
    Sold Out
  </router-link>
  <!-- <a v-else-if="route.name === 'Showcase'" href="https://www.eventbrite.com/e/845912817007">Register Now</a> -->
  <router-link v-else-if="route.name === 'Registration'" to="/2024/Registration/Form">
    Begin Registration
  </router-link>
  <router-link v-else to="/2024/Registration">Register Now</router-link>
</template>

<script setup>
const log = (message) => console.log(message); // eslint-disable-line
// import vue-route
import {useRoute} from "vue-router";
const route = useRoute(); // access the current route object
const props = defineProps(["soldOut"]); // eslint-disable-line
</script>

<style scoped>
P {
  color: white;
  font-size: 70px;
}
a {
  display: inline-block; /* Allows padding and width to be set */
  position: absolute;
  /* top: 25%; Adjust as necessary */
  left: 50%;
  transform: translateX(-50%); /* Centers the button */
  /* font-size: 1.73rem; */
  font-weight: bold;
  color: white;
  background-color: var(--brand-color);
  padding: 0.6rem 2.3rem;
  border-radius: 17px; /* Example value to increase rounded corners */
}
.narrow a {
  padding: 0.6rem 1.3rem;
}
/* Button-like styling for anchor tags */
a {
  text-align: center; /* Center the text inside the button */
  text-decoration: none; /* Removes underline from links */
  border: none; /* Removes border */
  cursor: pointer; /* Changes the cursor to indicate clickable items */
}

/* Hover effect */
:is(a:hover, a:focus) {
  color: white;
  background-color: var(--brand-hover-color); /* Darkens */
  text-decoration: none; /* Ensures text remains not underlined on hover */
  outline: none; /* Removes outline to keep the design clean */
}
/* Active (click) effect */
a:active {
  background-color: var(--brand-hover-color); /* darken */
}
.soldOut {
  background-color: red;
}
</style>
