<!-- Copyright: Seán I. O'Donoghue -->

<template>
  <div id="container">
    <h4>Apply ISCB Discount</h4>
    <p>To receive a 15% discount for these events, enter the email address associated with your ISCB membership:</p>
    <input
      id="email"
      ref="email"
      v-model="email"
      name="email"
      type="text"
      placeholder="Enter your ISCB email"
      :disabled="iscb_member"
      @keyup.enter="lookupEmail" />
    <ButtonBespoke :outstanding="true" :disabled="!validEmailSyntax" @click="lookupEmail">Verify email</ButtonBespoke>
    <div v-if="validation_outcome" id="message">{{ validation_outcome }}</div>

    <form id="poster_upload_form" method="post" action="Scripts/upload.php/" enctype="multipart/form-data">
      <table CELLSPACING="10">
        <tbody>
          <tr>
            <td id="label_column"><label>Upload:</label></td>
            <td align="left">
              <input id="posterNotArt" type="radio" name="upload" value="Poster" checked onclick="showPosterFields()" />
              Poster + Lightning Talk<br />
              <input
                id="virtualPoster"
                type="radio"
                name="upload"
                value="virtualPoster"
                onclick="showVirtualPosterFields()" />
              Virtual Poster + Video Presentation<br />
              <input
                id="artandbiology"
                type="radio"
                name="upload"
                value="artandbiology"
                onclick="showArtandBiologyFields()" />
              Art &amp; Biology Artwork<br />
              <p class="artofbiologyff" style="display: none; margin: 0; color: red">
                Choosing this option deletes any previously uploaded poster or image.
              </p>
            </td>
          </tr>

          <tr>
            <td><label>Your Email:</label></td>
            <td align="left">
              <input
                id="email1"
                type="text"
                size="28"
                maxlength="1000"
                name="email1"
                value=""
                title="Please type the exact email you used for registration" />
            </td>
          </tr>
          <tr>
            <td><label id="posterImageLabel">Poster (PNG, &#8804; 50MB):</label></td>
            <td align="left">
              <input
                id="posterFile"
                type="file"
                size="28"
                name="posterFile"
                title="Upload your poster or a representative image" />
            </td>
          </tr>
          <tr style="background: #eee">
            <td><label class="posterField">Lightning Talk:</label></td>
            <td />
          </tr>
          <tr class="lightningField" style="background: #eee">
            <td><label>Image 1 (PNG, &lt; 8MB):</label></td>
            <td align="left">
              <input
                id="lightningImage1"
                type="file"
                size="28"
                name="lightningImage1"
                title="Upload 1-3 images to accompany your Lightning Talk" />
            </td>
          </tr>
          <tr class="lightningField" style="background: #eee">
            <td><label>Image 2 (PNG, &lt; 8MB):</label></td>
            <td align="left">
              <input
                id="lightningImage2"
                type="file"
                size="28"
                name="lightningImage2"
                title="Upload 1-3 images to accompany your Lightning Talk" />
            </td>
          </tr>
          <tr class="lightningField" style="background: #eee">
            <td><label>Image 3 (PNG, &lt; 8MB):</label></td>
            <td align="left">
              <input
                id="lightningImage3"
                type="file"
                size="28"
                name="lightningImage3"
                title="Upload 1-3 images to accompany your Lightning Talk" />
            </td>
          </tr>
          <tr class="videoField">
            <td><label>Video (AVI, MOV or MP4; &lt; 50MB):</label></td>
            <td align="left">
              <input
                id="videoFile"
                type="file"
                size="28"
                name="videoFile"
                title="Maximum 60 seconds in AVI, MOV or MP4 format" />
            </td>
          </tr>

          <tr>
            <td><label id="titleLabel">Poster Title:</label></td>
            <td align="left">
              <input
                id="title"
                name="title"
                type="text"
                size="28"
                maxlength="1000"
                value=""
                title="&#8804; 90 characters" />
            </td>
          </tr>
          <tr>
            <td><label>Author(s):</label></td>
            <td align="left">
              <input
                id="author"
                type="text"
                size="28"
                maxlength="1000"
                name="author"
                value=""
                title="John Doe, John Smith, ..." />
            </td>
          </tr>
          <tr>
            <td><label>Institute Address:</label></td>
            <td align="left">
              <input
                id="institute"
                type="text"
                size="28"
                maxlength="1000"
                name="institute"
                value=""
                title="Institute1, City, Country; Institute2, ..." />
            </td>
          </tr>
          <tr>
            <td><label id="abstractLabel">Abstract:</label></td>
            <td align="left">
              <input
                id="abstract"
                type="text"
                size="28"
                maxlength="1200"
                name="abstract"
                value=""
                title="&#8804; 1,200 characters, single paragraph with no line breaks, no special characters, HTML, or other formating" />
            </td>
          </tr>

          <tr>
            <td><label>URL:</label></td>
            <td align="left">
              <input
                id="url"
                type="text"
                size="28"
                maxlength="2083"
                name="url"
                value="http://"
                title="Link to further information about your work" />
            </td>
          </tr>

          <tr class="posterField">
            <td><label>Category:</label></td>
            <td align="left">
              <select
                id="category"
                name="category"
                width="223"
                style="width: 210px"
                title="Select the category that best describes your poster">
                <option value="Genomes">Visualizing Genome Data</option>
                <option value="Transcripts">Visualizing Transcript Data</option>
                <option value="Proteins">Visualizing Proteins &amp; Complexes</option>
                <option value="Cells">Visualizing Cellular Systems</option>
                <option value="Organisms">Visualizing Organism Data</option>
                <option value="Populations">Visualizing Population Data</option>
                <option value="Other">Other</option>
              </select>
            </td>
          </tr>

          <tr class="posterField">
            <td><label>I cannot attend:</label></td>
            <td align="left">
              <select
                id="cannot_attend"
                name="cannot_attend"
                width="223"
                style="width: 210px"
                title="Tell us if you there is a particular session where you will not be available to present your poster. Leave blank if you will be available for all sessions.">
                <option value="" />
                <option value="A">Session A (March 29, midday)</option>
                <option value="B">Session B (March 29, evening)</option>
                <option value="C">Session C (March 30, midday)</option>
                <option value="D">Session D (March 31, midday)</option>
                <!--								<option value="E">Session E (7 March, midday)</option> -->
              </select>
            </td>
          </tr>
          <tr style="display: none">
            <!-- class="posterField"  -->
            <td><label>Request computer display?</label></td>
            <td align="left">
              <input
                id="screen"
                type="checkbox"
                name="screen"
                title="Please check box if you prefer a poster location with accompanying computer display." />
            </td>
          </tr>

          <!-- <tr class="posterField">
  <td><label>I request a <a href="/2012/Registration#CPP">CPP fellowship</a> because:</label></td>
  <td align="left"><input type="text" size="28" maxlength = "1200" name="CPP" id="CPP" value="" title="If you are applying for a CPP fellowship, state your reasons using &#8804; 5,000 characters with no special characters, HTML, or other formating." /></td>
  </tr> -->
          <tr>
            <td />
            <td align="left"><input type="submit" value="Submit" /></td>
            <!-- disabled="disabled"-->
          </tr>
        </tbody>
      </table>
    </form>
  </div>
  <DialogModal v-if="false" @hide="hitItAndQuit" />
</template>

<script>
import ButtonBespoke from "@/components/ButtonBespoke";
import DialogModal from "@/components/DialogModal.vue";
import stringify from "json-stringify-safe";
export default {
  name: "PosterUploadForm",
  // https://stackoverflow.com/q/64220737
  components: {
    ButtonBespoke,
    DialogModal
  },
  emits: ["emailValid", "hideDialog"],
  data() {
    return {
      email: "",
      iscb_member: false,
      validation_outcome: ""
    };
  },
  computed: {
    validEmailSyntax() {
      this.log(`Called validEmailSyntax()`);
      // https://stackoverflow.com/q/46155
      let regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return String(this.email).toLowerCase().match(regex);
    }
  },
  mounted() {
    document.getElementById("email")?.focus(); // set initial focus to input
  },
  methods: {
    // eslint-disable-next-line
    log(message) {
      if (process.env.NODE_ENV === "development") {
        //console.log(message); // uncomment to show logs from this component
      }
    },
    hitItAndQuit() {
      this.log(`Iscb/hitItAndQuit()`);
      this.$emit("hideDialog");
    },
    async lookupEmail() {
      this.log(`email = ${this.email}`);
      if (!this.validEmailSyntax) {
        this.log("email syntax not yet valid");
        return; // early return
      }
      const url = `${process.env.VUE_APP_API}/iscb/member`;
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: stringify({
            email: this.email
          })
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const outcome = await response.json();
        if (outcome) {
          this.iscb_member = outcome.iscb_member;
          this.log(stringify(outcome));
          if (this.iscb_member) {
            this.$emit("emailValid"); // , 'someValue')
            this.validation_outcome = "";
            //'Use the buttons below to register with ISCB discount.';
          } else {
            this.validation_outcome = "This email is not linked to an ISCB membership.";
            // move focus back to input field
            // https://michaelnthiessen.com/set-focus-on-input-vue
            this.$refs["email"].focus();
          }
        } else {
          this.validation_outcome = "Cannot access ISCB database; Please try later.";
        }
        return;
      } catch (error) {
        this.log(`Request failed: ${JSON.stringify(error)}`);
      }
      // Here are .htaccess settings needed to get this working:
      // Header add Access-Control-Allow-Credentials true
      // Header add Access-Control-Allow-Origin "*"
      // Header add Access-Control-Allow-Headers "origin, x-requested-with, content-type, Access-Control-Allow-Origin"
      // Header add Access-Control-Allow-Methods: "GET,POST,OPTIONS,DELETE,PUT"
      // Vary: Origin
      //
    }
  }
};
</script>

<style scoped>
input {
  margin: 0px;
  padding: 0px;
}

label,
th {
  padding-right: 12px;
  color: #999;
  font-size: 80%;
  font-weight: 900;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

table {
  border-collapse: collapse;
  margin-bottom: 1em;
  width: 100%;
}

tr {
  vertical-align: top;
}

td,
th {
  padding: 6px;
}

tr:nth-child(even) {
  background: #eee;
}

tr:nth-child(even) td:first-child {
  /*color: #787878;*/
}

/*** Poster upload ***/
#poster_upload_form,
#talk_upload_form {
  text-align: right;
  margin-top: 1.1em;
  padding: 1.1em;
}

#poster_upload_form table,
#talk_upload_form table {
  /* width: 700px; */
}

#poster_upload_form #label_column,
#talk_upload_form #label_column {
  width: 18em;
}

#poster_upload_form label.error,
#talk_upload_form label.error {
  color: #ff0080;
  margin-left: 8px;
  font-size: 10px;
  text-transform: none;
}

.section #poster_upload_form table tr td,
.section #poster_upload_form label {
  color: #333333;
}

#poster_upload_form,
.section #poster_upload_form tr:nth-child(even) {
  background: #dddddd;
}
</style>
