<!-- Our mission: to advance DataVis across the life sciences -->

<template>
  <AddTweets>
    <!-- prettier-ignore -->
    <h3>About VIZBI</h3>
    <p>
      Visualization is increasingly important in the life sciences as data grows rapidly in volume
      and complexity. The VIZBI initiative is an international conference series bringing together
      researchers developing and using computational visualization to address a broad range of
      biological research areas; the conference also attracts participation from medical
      illustrators, graphic designers, and graphic artists. VIZBI is held annually, alternating
      between Europe and the USA.
    </p>
    <!-- <b><router-link to="2024">VIZBI 2024</router-link></b> -->
    <p>
      VIZBI 2025, the 15th international meeting on Visualizing Biological Data, is planned for
      April 8–11 in Cambridge UK, with a virtual participation option. Confirmed keynote speakers:
      <a href="https://jku-vds-lab.at/persons/streit">Marc Streit</a> (<i>'Explainable AI'</i>);
      <a href="https://giorgialupi.com/about">Giorgia Lupi</a> (<i>'Data-driven storytelling'</i>);
      and <a href="https://tobias.isenberg.cc/">Tobias Isenberg</a> (<i>
        'Exploring multidimensional data with AI'</i
      >). Further details will be announced shortly.
    </p>
    <p>
      To help the broader research community benefit from VIZBI,
      <router-link to="Videos">videos</router-link> &amp; slides from talks at previous meetings are
      available, as are <router-link to="Posters">posters</router-link>. The VIZBI community have
      also produced a
      <a href="https://www.nature.com/nmeth/volumes/7/issues/3">special issue of Nature Methods</a>,
      with reviews that provide practical guidelines on current methods and tools for visualizing
      biological data.
    </p>
    <p>
      In addition to the annual conference, we organize
      <router-link to="Plus">VIZBI showcase events</router-link> (formerly 'VizbiPlus'),
      highlighting how visualization is being used to communicate hypotheses about complex
      biological phenomena.
    </p>
    <!-- only need to show below if consent is not yet given -->
    <p v-if="consent">
      By using this site, you consent to our
      <router-link to="/Terms" @click.stop.prevent>cookie policy</router-link>.
    </p>
  </AddTweets>
</template>

<script setup>
import AddTweets from "@/components/AddTweets.vue";
import {ref} from "vue";
const consent = ref(localStorage.GdprConsent || false);
</script>

<style scoped>
i {
  font-weight: lighter !important;
}
</style>
