<!-- Copyright: Sean O'Donoghue -->

<!-- eslint-disable vue/no-v-html -->
<template>
  <tr v-for="(event, eventIndex) in programDay.events" :key="eventIndex">
    <td v-if="event.type === 'session'" colspan="3">
      <b> {{ event.title }} </b> <br />
      <span v-if="event.subtitle">
        <i> {{ event.subtitle }}: </i>
        <a v-if="event.url" :href="event.url">
          <span v-if="event.name">{{ event.name }}</span>
          <span v-else-if="event.venue">{{ event.venue }}</span>
        </a>
        <span v-else-if="event.name">{{ event.name }}</span>
        <span v-else-if="event.venue">{{ event.venue }}</span>
      </span>
    </td>
    <td v-if="event.type !== 'session'">
      {{ formatTime(event.start) }}
      <!--  show only start times on narrow screens -->
      <span v-if="event.end" class="wideScreenOnly"> - {{ formatTime(event.end) }} </span>
    </td>
    <td v-if="event.type !== 'session'">
      <VideoIcon :event="event" />
    </td>
    <td v-if="event.type !== 'session'">
      <span class="event">
        <!-- wrapper for arrows -->
        <span v-if="!description" class="arrows" @click="toggleDescription(event.ref)">
          <span v-if="event.description" :ref="setRef(event.ref + 'RightArrow')" class="right chevronArrow" />
          <span v-if="event.description" :ref="setRef(event.ref + 'DownArrow')" class="hide down chevronArrow" />
        </span>
        <span v-if="event.title" class="eventTitle">
          <Slides :name="event.name" align="left" />
          <span v-if="event.type === 'keynote'"><b>Keynote: </b></span>
          <span v-html="event.title" />
          <template v-if="event.name && event.url">
            (<a :href="event.url"> {{ event.name }} </a>)
          </template>
          <template v-else-if="event.name"> ({{ event.name }}) </template>
          <p
            v-if="event.description"
            :ref="setRef(event.ref)"
            :class="{hide: !description, description: true}"
            v-html="event.description" />
        </span>
      </span>
    </td>
  </tr>
</template>

<script setup>
const log = (message) => console.log(message); // eslint-disable-line
const props = defineProps(["programDay", "session", "description", "timeZoneSelected"]);
import Slides from "@/components/People/Person/Slides";
import VideoIcon from "@/components/Program/VideoIcon";

// const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

// enable toggling of event descriptions
import {reactive} from "vue";
const refs = reactive({});
function setRef(index) {
  return (el) => {
    if (el) refs[index] = el; // Ensure element exists
  };
}
function toggleDescription(refKey) {
  log(`toggleDescription(${refKey})`);
  const element = refs[`${refKey}`];
  const rightArrow = refs[`${refKey}RightArrow`];
  const downArrow = refs[`${refKey}DownArrow`];
  if (element) {
    log(`toggleDescription: element found`);
    if (element.classList.contains("hide")) {
      log(`elements currently hidden, so show them`);
      element.classList.remove("hide");
      rightArrow.classList.add("hide");
      downArrow.classList.remove("hide");
    } else {
      log(`elements currently shown, so hide them`);
      element.classList.add("hide");
      downArrow.classList.add("hide");
      rightArrow.classList.remove("hide");
    }
  }
}

// function addMinutes(date, minutes) {
//   return new Date(date.getTime() + minutes * 60000); // return date
// }
function formatTime(date) {
  if (!date) return; // early exit until date set
  if (!props.timeZoneSelected) return; // early exit until timezone set
  if (props.timeZoneSelected.match(/London|Australia|America/)) {
    return date.toFormat("h:mma").toLowerCase(); // use 12 hour clock + AM/PM
  } else {
    return date.toFormat("T").toLowerCase(); // use 24 hour clock
  }
}
// function icon(location) {
//   log(`icon(${location})`);
//   let file = "";
//   if (location.match(/online\s*\+\s*on-?site/i)) {
//     log(`icon: match Online + on-site`);
//     file = "onsite+online.png";
//   } else if (location.match(/online\s*only/i)) {
//     log(`icon: match online only`);
//     file = "online_only.png";
//   } else {
//     return "";
//   }
//   return require(`@/assets/img/${file}`);
// }
</script>

<style scoped>
#programTable {
  clear: both; /* https://stackoverflow.com/q/2580772 */
  margin-top: 3.8em;
  margin-bottom: 2em;
}
tr:nth-child(even) {
  /*  https://stackoverflow.com/q/32867605 */
  background-color: var(--background-color-subtle);
}
.eventTable {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1em;
}
.eventTable td,
th {
  padding: 6px;
  vertical-align: top;
}
/* https://stackoverflow.com/q/27788092 */
.eventTable tr td:first-child {
  width: 19%;
}
.eventTable tr td:nth-child(2) {
  width: 2%;
}
.eventTable tr td:nth-child(3) {
  width: 79%;
}
h4 {
  display: inline;
  font-weight: 600 !important;
}
h5 {
  font-weight: 600 !important;
  /* this don't work - don't know why */
  margin-top: 22px;
  padding-top: 0px;
  margin-bottom: 6px;
  padding-bottom: 0px;
}
.h5_subheading {
  font-weight: 200 !important;
}
.event {
  display: flex; /* outer container for event arrow / title / description */
}
.eventTitle {
  flex-direction: column;
}
span.arrows {
  display: flex;
  flex-direction: column;
  color: #666666;
  padding-left: 0px;
  margin-left: 0px;
  cursor: pointer;
}
/* https://stackoverflow.com/q/27492191 */
.chevronArrow {
  border-right: 0.1em solid #7f7f7f;
  border-top: 0.1em solid #7f7f7f;
  width: 0.4em;
  height: 0.4em;
  margin-top: 0.4em;
  margin-left: 0em;
  margin-right: 0.6em;
  margin-bottom: 0.1em;
}
.hide {
  display: none;
}
.right {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.down {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
span.chevronArrow:hover,
.down {
  border-color: var(--brand-color);
}
/* key adjustments for small screen */
.narrow h4,
.narrow h4 {
  font-size: 1.25rem;
}
.narrow .eventTable col.first,
.medium .eventTable col.first {
  width: 8%;
}
.narrow .eventTable col.second,
.medium .eventTable col.second {
  width: 3%;
}
.narrow .eventTable col.third,
.medium .eventTable col.third {
  width: 89%;
}
.narrow .wideScreenOnly,
.medium .wideScreenOnly {
  /* show only start times on narrow screens */
  display: none;
}
ul.legend {
  margin-left: 0px;
  padding-left: 0px;
  margin-top: 12px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  font-weight: 300;
}
ul.legend li {
  display: inline;
}
li + li {
  margin-left: 6px;
}
li:not(:first-of-type) + li::before {
  margin-right: 6px;
  content: " | "; /* https://stackoverflow.com/q/9171699 */
}
</style>
