<template>
  <p>
    The VIZBI Masterclass is an online-only, intensive training event taking place over two
    half-days (March 27-28). The event covers state-of-the-art methods in data visualization, taught
    by leading experts and practitioners.
    <router-link to="/2023/Registration">
      <span class="buttonish">Register&nbsp;now</span>
    </router-link>
  </p>
  <router-link to="/2023/Registration" title="Click to register">
    <HeroImage hero-image="Masterclass.jpeg" credit="O&#39;Donoghue, Stolte, Kozlíková, Viola" />
  </router-link>
  <p>
    This event is a satellite meeting to the <router-link to="/2023">VIZBI Workshop</router-link>,
    which starts immediately following the Masterclass.
  </p>

  <p>
    The VIZBI Masterclass will be online-only and runs for eight-hours, split over two days (Monday
    and Tuesday, March 27-28), from
    <a
      href="https://www.timeanddate.com/worldclock/meetingtime.html?iso=20230327&p1=37&p2=136&p3=179&p4=224">
      14:00-18:00</a
    >
    Central European time. To join the Masterclass, sign-up on the
    <router-link to="/2023/Registration">registration page</router-link>.
  </p>

  <p>
    <i>
      Registration for the VIZBI Masterclass is separate to the VIZBI Workshop. To attend both
      events, you must submit two registrations.</i
    >
  </p>

  <h4>Who is the Masterclass for?</h4>
  <p>
    We have designed the Masterclass primarily to help life scientists. However, it will also be
    useful for anyone working with large, complex datasets &mdash; this includes scientists working
    in other fields, as well as programmers, designers, and communicators.
  </p>

  <h4>Why should I attend?</h4>
  <p>
    Data visualization is a powerful tool that can help you find insights buried in large, complex
    data, and to communicate more effectively. Unfortunately, there are currently few opportunities
    to get DataVis training. By attending the VIZBI Masterclass, you can learn directly from leading
    practitioners who have developed some of the cutting-edge DataVis methods.
  </p>

  <h4>What will I learn?</h4>
  <p>This course provides you the opportunity to:</p>
  <ul>
    <li>
      Learn key principles of human visual perception, and get an overview of current DataVis
      techniques and tools;
    </li>
    <li>
      Learn general strategies for creating insightful visual representations from large, complex,
      multidimensional datasets;
    </li>
    <li>Learn how to create clear and compelling visualizations for communication;</li>
    <li>Learn how to choose design elements and attributes, with an emphasis on typography;</li>
    <li>
      Learn how to create powerful, interactive visualizations using cutting-edge GPU programming
      methods;
    </li>
    <li>Learn about newly emerging strategies for visualizing time-oriented data;</li>
    <li>Connect with leading data visualization experts and practitioners.</li>
  </ul>

  <h4>What do I need to know to participate?</h4>
  <p>
    The VIZBI Masterclass has been designed to be accessible by anyone working with data on a
    regular basis. Some limited programming experience is recommended for the session on GPU-driven
    interactive visualization.
  </p>

  <h4>Further questions?</h4>
  <p>
    If you have further questions about the VIZBI Masterclass, please write to
    <Email to="masterclass" />.
  </p>

  <h4 id="both">
    <a name="both" />I'm already attending the VIZBI Workshop - why should I go to the Masterclass?
  </h4>
  <p style="display: inline">
    The Masterclass and Workshop are quite different, with little overlap in material. The
    Masterclass focuses on training on general methods, while the Workshop focuses on visualizations
    that address specific biological research challenges. The first day of the Masterclass
    introduces some principles that may be assumed knowledge for some of the Workshop talks. The
    second day of the Masterclass focuses on DataVis topics that will not be specifically addressed
    during the Workshop. So, if you have the time, money, and motivation, it can certainly make
    sense to attend both events. For VIZBI 2022, most participants and many speakers attended both
    events.
  </p>

  <p>
    <ButtonBespoke :outstanding="true" @click="$router.push('/2023/Registration')">
      Register now</ButtonBespoke
    >
  </p>
</template>

<script>
import ButtonBespoke from "@/components/ButtonBespoke";
import Email from "@/components/Email";
import HeroImage from "@/components/HeroImage.vue";
export default {
  components: {ButtonBespoke, Email, HeroImage}
};
</script>

<style scoped>
table td {
  vertical-align: top;
  margin-top: 29px;
  margin-bottom: 29px;
}
span.buttonish {
  color: white;
  background-color: var(--brand-color);
  padding: 2px 4px;
  border-radius: 4px;
}
img {
  width: 100%;
}
p button {
  margin-top: 16px;
  margin-bottom: 16px;
}
li {
  margin-bottom: 0.3rem;
}
</style>
