<template>
  <h3>People</h3>
  <nav>
    <Dropdown v-show="!day" placeholder="Session" :options="sessions" :selected="category" @update="selectCategory" />
    <Dropdown v-show="!category" placeholder="Day" :options="dates" :selected="day" @update="selectDay" />
    <Dropdown
      v-show="!category && !day"
      placeholder="Sort by..."
      :options="['First name', 'Last name', 'Session']"
      :selected="sortBy"
      :tag="false"
      @update="selectSort" />
    <ButtonBespoke v-show="category || day" class="showAllButton" @click="showAll">Show All</ButtonBespoke>
  </nav>
  <!-- currently, highlights only on localhost and dev.vizbi.org -->
  <!--   "host !== 'vizbi.org'"      -->
  <PeopleGallery
    name="Speakers"
    :highlights="true"
    :category="category"
    :day="day"
    :show-tags="true"
    :sort="sortBy"
    @foundDays="accumulateDays"
    @updateDay="selectDay"
    @foundCategories="accumulateSessions"
    @updateCategory="selectCategory" />
  <PeopleGallery
    name="Session Chairs"
    :category="category"
    :day="day"
    :show-tags="true"
    :sort="sortBy"
    @foundDays="accumulateDays"
    @updateDay="selectDay"
    @foundCategories="accumulateSessions"
    @updateCategory="selectCategory" />
  <PeopleGallery
    name="Organizers"
    :category="category"
    :day="day"
    :show-tags="true"
    :sort="sortBy"
    @foundCategories="accumulateCategories"
    @updateCategory="selectCategory" />
</template>

<script setup>
import ButtonBespoke from "@/components/ButtonBespoke";
import Dropdown from "@/components/Dropdown.vue";
import PeopleGallery from "@/components/People/Gallery.vue";
import stringify from "json-stringify-safe";
import {ref} from "vue";

const category = ref(""); // currently selected category
const categories = ref([]);
const dates = ref([]);
const day = ref(""); // currently selected day
const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
//const host = window.location.host;
const sessions = ref([]);
const sortBy = ref(localStorage.getItem("sortBy") || "Session");

// eslint-disable-next-line
function log(message) {
  if (process.env.NODE_ENV === "development") {
    console.log(message); // uncomment to show logs in component
  }
}

const sortSessionNames = (sessions) => {
  log(`sortSessionNames()`);
  sessions.value = sessions.sort((a, b) => {
    if (a === "Keynote") {
      return -1; // keep order
    } else if (b === "Showcase") {
      return 1; // swap order
    } else if (a === "Masterclass" && b !== "Masterclass") {
      return 1; // swap order
    } else if (a !== "Masterclass" && b === "Masterclass") {
      return -1; // keep order
    } else if (a.includes("Art") && !b.includes("Art")) {
      return 1; // swap order
    } else if (!a.includes("Art") && b.includes("Art")) {
      return -1; // keep order
    } else {
      return 0; // keep order
    }
  });
  return sessions;
};
const accumulateDays = (days) => {
  log(`accumulateDays called`);
  days.forEach((day) => {
    if (!dates.value.includes(day)) {
      dates.value.push(day);
    }
  });
};
const accumulateCategories = (mergedCategories) => {
  log(`accumulateCategories(${stringify(mergedCategories)})`);
  if (sessions.value.length > 0) {
    mergedCategories = mergedCategories.concat(sessions.value);
    log(`accumulateCategories: session = ${stringify(sessions.value)}`);
  }
  if (categories.value.length > 0) {
    mergedCategories = mergedCategories.concat(categories.value);
    log(`accumulateCategories: categories = ${stringify(categories.value)}`);
  }
  let uniqueCategories = [];
  mergedCategories.forEach((category) => {
    category = regularizeCategoryNames(category);
    if (!uniqueCategories.includes(category)) {
      uniqueCategories.push(category);
    }
  });
  categories.value = uniqueCategories;
  log(`categories = ${stringify(categories.value)}`);
  if (category.value && !categories.value.includes(category.value)) {
    log(`category is set to something weird - get rid of it`);
    category.value = "";
    clearFragment(); // remove hash if it don't match day or category
  }
};
const accumulateSessions = (sessionsToAdd) => {
  log(`accumulateSessions called`);
  sessionsToAdd.forEach((session) => {
    session = regularizeCategoryNames(session);
    if (!sessions.value.includes(session)) {
      sessions.value.push(session);
    }
  });
  sessions.value = sortSessionNames(sessions.value);
};
const selectCategory = (categorySelected) => {
  log(`selectCategory(${categorySelected})`);
  if (categorySelected) {
    category.value = categorySelected; // change to session selected in PeopleGroup
    day.value = ""; // deselect day
    setFragment(categorySelected);
  } else {
    // if the child (PeopleGroup) sends an empty string
    category.value = ""; // deselect session
    day.value = ""; // deselect day
    clearFragment();
  }
};
const selectDay = (daySelected) => {
  log(`selectDay(${daySelected})`);
  if (daySelected) {
    day.value = daySelected; // change to day selected in child (PeopleGroup)
    category.value = ""; // deselect category
    setFragment(daySelected);
  } else {
    // if the child (PeopleGroup) sends an empty string
    day.value = ""; // deselect day
    category.value = ""; // deselect category
    clearFragment();
  }
};
const selectSort = (method) => {
  if (method) {
    sortBy.value = method; // change to method selected in child
    // remember sort method is in localStorage
    localStorage.setItem("sortBy", method);
  } else {
    sortBy.value = "Session"; // default sorting method
  }
};
const showAll = () => {
  day.value = ""; // deselect day
  category.value = ""; // deselect category
  clearFragment();
};
const regularizeCategoryNames = (category) => {
  // Rename categories to shorten name
  if (category === "Cellular Systems") {
    return "Cells";
  } else if (category === "Tissues & Organisms") {
    return "Tissues";
  } else if (category === "Populations & Ecosystems") {
    return "Ecosystems";
  } else {
    return category;
  }
};
import {useRoute} from "vue-router";
const route = useRoute();
const setFragment = async (selection) => {
  log(`setFragment(${selection})`);
  log(`setFragment: path = ${route.path}`);
  history.pushState({}, null, route.path + "#" + selection.replace(/ /g, "_"));
  let url = "#" + selection.replace(/ /g, "_");
  history.replaceState(history.state, "", url);
};
const clearFragment = () => {
  log(`clearFragment()`);
  // https://stackoverflow.com/q/1397329
  history.pushState(
    {},
    null,
    window.location.pathname + window.location.search
    //document.title, window.location.pathname + window.location.search
  );
  // history.pushState('', '', '');
};

import {onMounted} from "vue";
onMounted(() => {
  log(`People.vue/mounted()`);
  log(`('${category.value}', '${day.value}', '${sortBy.value}')`);
  log(`categories = ${stringify(categories.value)}`);
  if (window.location.hash) {
    let hash = decodeURIComponent(window.location.hash);
    log(`URL contains hash: '${hash}'`);
    hash = hash.split(/&/)[0].replace(/#/, "");
    log(`first hash parameter = ${hash}`);
    if (hash.match(/_/)) {
      log(`first parameter contains underscore - must be person name`);
      return;
    } else if (days.includes(hash)) {
      log(`first parameter is a day`);
      day.value = hash;
    } else {
      log(`for now, assume first parameter is a category`);
      category.value = hash;
      log(`hash: category = ${category.value}`);
    }
  }
});

import {watch} from "vue";
watch(route, (to, from) => {
  log(`people: change from '${from.name}' to '${to.name}'`);
  if (to.name.match(/people/i) && !from.name.match(/people/i)) {
    // if a day or category is selected, add hash to the URL
    if (day.value) {
      setFragment(day.value);
    } else if (category.value) {
      setFragment(category.value);
    }
  }
});
</script>

<style scoped>
.showAllButton {
  margin-left: 12px;
  /* same as Dropdown.vue > .container + .container */
}

#organizerButton {
  margin-left: 20px;
  font-size: 16px;
  padding: 10px 20px 10px 10px;
  text-transform: none;
  font-weight: 300;
}

.organizerTitle {
  background: #ff43bb;
  padding: 4px;
  border-radius: 3px;
  color: white;
}

#showAll {
  /* margin-left: 15px;
    font-size: 14px;
    padding: 8px 5px; */
  margin-left: 20px;
  font-size: 0.9rem;
  padding: 10px;
  text-transform: none;
  font-weight: 300;
}
</style>
